export const ledger_data = {
  "2020-01-01": 52439375,
  "2020-02-01": 53130945,
  "2020-03-01": 53788668,
  "2020-04-01": 54491139,
  "2020-05-01": 55161226,
  "2020-06-01": 55853244,
  "2020-07-01": 56515135,
  "2020-08-01": 57212508,
  "2020-09-01": 57893329,
  "2020-10-01": 58551329,
  "2020-11-01": 59249125,
  "2020-12-01": 59920826,
  "2021-01-01": 60604963,
  "2021-02-01": 61286110,
  "2021-03-01": 61906480,
  "2021-04-01": 62601075,
  "2021-05-01": 63268331,
  "2021-06-01": 63962337,
  "2021-07-01": 64643597,
  "2021-08-01": 65350822,
  "2021-09-01": 66057107,
  "2021-10-01": 66729879,
  "2021-11-01": 67411042,
  "2021-12-01": 68054403,
  "2022-01-01": 68718531,
  "2022-02-01": 69397632,
  "2022-03-01": 70010911,
  "2022-04-01": 70694438,
  "2022-05-01": 71355631,
  "2022-06-01": 72042228,
  "2022-07-01": 72707836,
  "2022-08-01": 73395569,
  "2022-09-01": 74091726,
  "2022-10-01": 74762640,
  "2022-11-01": 75454710,
  "2022-12-01": 76127020,
  "2023-01-01": 76820125,
  "2023-02-01": 77504505,
  "2023-03-01": 78120606,
  "2023-04-01": 78804683,
  "2023-05-01": 79475649,
  "2023-06-01": 80168793,
  "2023-07-01": 80840512,
  "2023-08-01": 81540733,
  "2023-09-01": 82238079,
  "2023-10-01": 82914705,
  "2023-11-01": 83615436,
  "2023-12-01": 84291281,
  "2024-01-01": 84982705
}

export const ledger_data_keys = Object.keys(ledger_data) as unknown as Array<keyof typeof ledger_data>;
