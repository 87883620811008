import { chakra, HTMLChakraProps, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'

export const Logo = (props: HTMLChakraProps<'svg'>) => {
  return (
    <chakra.svg
      color={useColorModeValue('blue.500', 'blue.300')}
      aria-hidden
      viewBox="174.01 85.474 127.866 127.866"
      fill="none"
      h="5"
      flexShrink={0}
      {...props}
    >
      <circle cx="237.943" cy="149.407" r="63.933" fill="rgb(83, 86, 227)" />
      <path
        d="M 253.20399438476562 185.0159970703125 Q 260.2989943847656 185.0159970703125 266.10399438476566 187.1444970703125 Q 271.9089943847656 189.2729970703125 277.3269943847656 193.0139970703125 L 269.3289943847656 203.3339970703125 Q 261.5889943847656 196.4969970703125 254.62299438476563 193.9169970703125 Q 247.65699438476562 191.3369970703125 235.78899438476563 191.3369970703125 Q 224.69499438476564 191.3369970703125 216.30999438476562 186.1124970703125 Q 207.92499438476563 180.8879970703125 203.2164943847656 170.6324970703125 Q 198.50799438476562 160.37699707031248 198.50799438476562 145.7999970703125 Q 198.50799438476562 131.4809970703125 203.2164943847656 121.0319970703125 Q 207.92499438476563 110.5829970703125 216.43899438476564 105.1004970703125 Q 224.95299438476562 99.6179970703125 236.04699438476564 99.6179970703125 Q 247.26999438476562 99.6179970703125 255.71949438476562 104.9714970703125 Q 264.1689943847656 110.32499707031249 268.8774943847656 120.6449970703125 Q 273.5859943847656 130.9649970703125 273.5859943847656 145.6709970703125 Q 273.5859943847656 161.2799970703125 268.42599438476566 170.5034970703125 Q 263.26599438476563 179.7269970703125 253.20399438476562 185.0159970703125 Z M 211.53699438476562 145.7999970703125 Q 211.53699438476562 164.2469970703125 218.05149438476562 172.8899970703125 Q 224.56599438476562 181.5329970703125 236.04699438476564 181.5329970703125 Q 260.55699438476563 181.5329970703125 260.55699438476563 145.6709970703125 Q 260.55699438476563 109.6799970703125 236.04699438476564 109.6799970703125 Q 224.43699438476563 109.6799970703125 217.98699438476564 118.4519970703125 Q 211.53699438476562 127.22399707031249 211.53699438476562 145.7999970703125 Z"
        fill="rgb(248, 248, 248)"
        style={{ whiteSpace: 'pre' }}
      />
    </chakra.svg>
  )
}
